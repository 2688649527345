import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import TopBar from "../components/TopBar";
import BottomBar from "../components/BottomBar";
import { navigate } from "gatsby";
import { ToHindi } from "../common/ToHindi";
import MetaTags from "../components/MetaTags";
import { Chapter } from "../models/Chapter";
import { VerseModel } from "../models/VerseModel";
import { ChapterInfo } from "../models/ChapterInfo";
import "../css/global-styles.css";
import { goToUrl } from "../constants/BaseConstants";
import VersesDialog from "../components/VersesDialog";
import ChaptersMenu from "../components/ChaptersMenu";

const titleColor = "#177245";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function VerseByVerse(props: any) {
  let chaptersInfo: ChapterInfo[];

  const useStyles = makeStyles(() => ({
    card: {
      maxWidth: "550px",
      margin: "auto",
      marginTop: "80px",
      marginBottom: "100px",
      padding: "15px"
    },
    cardArabicTitle: {
      direction: "rtl",
      fontFamily: "Uthmani",
      fontSize: "24px",
      color: titleColor,
      paddingBottom: "0px",
      margin: 0,
      fontWeight: "normal"
    },
    numberArabic: {
      fontFamily: "Traditional Arabic",
      fontSize: "20px",
      color: titleColor
    },
    cardArabicBody: {
      direction: "rtl",
      fontFamily: "Uthmani",
      fontSize: "25px",
      color: "#222"
    },
    cardEnglishTitle: {
      fontSize: "18px",
      fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', // doing this because using a native element (MUI input text element does not support dir)
      direction: "ltr",
      color: titleColor,
      paddingTop: "10px",
      paddingBottom: "5px",
      margin: 0,
      fontWeight: "normal"
    },
    cardEnglishBody: {
      fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', // doing this because using a native element (MUI input text element does not support dir)
      direction: "ltr",
      fontSize: "18px",
      lineHeight: "140%",
      color: "#222"
    },
    numberEnglish: {
      fontSize: "16px",
      fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', // doing this because using a native element (MUI input text element does not support dir)
      color: titleColor
    },
    separator: {
      height: "20px"
    }
  }));

  const [anchorChapterMenu, setAnchorChapterMenu] = React.useState(null);
  const [isVerseDialogOpen, setIsVerseDialogOpen] = React.useState(false);
  const [isRootURL, setIsRootURL] = React.useState(true);

  if (typeof document !== "undefined") {
    document.body.style.backgroundColor = "#ffffff";
  }

  const classes = useStyles(props);

  const { pageContext } = props;
  const { verseNumber } = pageContext;

  const chapter: Chapter = props.data.allTranslationJson.nodes[0];

  chaptersInfo = props.data.allQuranInfoJson.nodes;

  React.useEffect(() => {
    setIsRootURL(location.pathname === "/");
  }, []);

  function handleGoToWebsite() {
    window.open(goToUrl, "_self");
  }

  function getUrlOfVerse(chapterNumber: number, verseNumber: number) {
    return `${goToUrl}/${chapter.chapterNumber}#${verseNumber}`;
  }

  function handleGoToVerse() {
    window.open(getUrlOfVerse(chapter.chapterNumber, verseNumber), "_self");
  }

  function nextVerseButtonClicked() {
    let nextVerse = verseNumber + 1;
    let nextChapter = chapter.chapterNumber;
    if (nextVerse > chapter.verses[chapter.verses.length - 1].verseNumber) {
      nextChapter = nextChapter + 1;
      if (nextChapter == 1 || nextChapter == 9) {
        nextVerse = 1;
      } else {
        nextVerse = 0;
      }
    }
    if (nextChapter > 114) {
      nextChapter = 1;
      nextVerse = 1;
    }
    navigate(`${nextChapter.toString()}/${nextVerse.toString()}`);
  }

  function prevVerseButtonClicked() {
    let nextVerse = verseNumber - 1;
    let nextChapter = chapter.chapterNumber;
    if (nextVerse < chapter.verses[0].verseNumber) {
      nextChapter = nextChapter - 1;
      if (nextChapter >= 1) {
        nextVerse = chaptersInfo[nextChapter - 1].nVerses;
      } else {
        nextChapter = 114;
        nextVerse = 6;
      }
    }
    navigate(`${nextChapter.toString()}/${nextVerse.toString()}`);
  }

  function handleChapterMenuItemClicked(index: number) {
    setAnchorChapterMenu(null);
    let nextChapter = index + 1;
    let nextVerse = nextChapter == 1 || nextChapter == 9 ? 1 : 0;
    navigate(`${nextChapter.toString()}/${nextVerse.toString()}`);
  }

  function handleVerseNumberClicked(verseNumber: number) {
    setIsVerseDialogOpen(false);
    navigate(`${chapter.chapterNumber.toString()}/${verseNumber.toString()}`);
  }

  return (
    <div>
      <MetaTags chapter={chapter} verseNumber={verseNumber} isRootURL={isRootURL} />
      <TopBar
        title="Quran"
        verseNumber={verseNumber}
        chapterNumber={chapter.chapterNumber}
        onTitleClicked={handleGoToWebsite}
        onNumbersClicked={handleGoToVerse}
      />
      <div className={classes.card}>
        <div lang="ar">
          <h2 className={classes.cardArabicTitle}>{`سورة ${chapter.nameArabic}`}</h2>
          <div className={classes.cardArabicBody}>
            {chapter.verses.filter((verse: VerseModel) => verse.verseNumber == verseNumber)[0].verseString}
            {verseNumber != 0 && <span className={classes.numberArabic}> ﴿{ToHindi(verseNumber, "ar")}﴾</span>}
          </div>
        </div>
        <div className={classes.separator} />
        <div>
          <h3 className={classes.cardEnglishTitle}>{`Surah ${chapter.nameTrans}`}</h3>
          <div className={classes.cardEnglishBody}>
            {verseNumber != 0 && <span className={classes.numberEnglish}>{verseNumber}. </span>}
            {chapter.verses.filter((verse: VerseModel) => verse.verseNumber == verseNumber)[0].verseStringB}
          </div>
        </div>
      </div>
      <BottomBar
        readInVerseClicked={handleGoToVerse}
        chapterSelectClicked={(event: React.MouseEvent<HTMLElement>) => setAnchorChapterMenu(event.currentTarget)}
        verseSelectClicked={() => setIsVerseDialogOpen(true)}
        nextVerseClicked={nextVerseButtonClicked}
        prevVerseClicked={prevVerseButtonClicked}
      />

      <ChaptersMenu
        anchor={anchorChapterMenu}
        chaptersInfo={chaptersInfo}
        chapterClicked={(chapterIndex: number) => handleChapterMenuItemClicked(chapterIndex)}
        handleMenuClose={() => setAnchorChapterMenu(null)}
        currentChapterNumber={chapter.chapterNumber}
      ></ChaptersMenu>

      <VersesDialog
        isOpen={isVerseDialogOpen}
        chapter={chapter}
        verseNumberClicked={(verseNumber: number) => handleVerseNumberClicked(verseNumber)}
        handleCloseDialog={() => setIsVerseDialogOpen(false)}
      ></VersesDialog>
    </div>
  );
}

VerseByVerse.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object
};

export default VerseByVerse;

export const quranQuery = graphql`
  query QuranInfoJson3($slug: Int! = 1) {
    allTranslationJson(filter: { chapterNumber: { eq: $slug } }) {
      nodes {
        verses {
          verseNumber
          verseString
          verseStringB
        }
        nameEnglish
        nameTrans
        nameArabic
        chapterNumber
      }
    }
    allQuranInfoJson {
      nodes {
        nVerses
        nameEnglish
        nameTrans
        nameArabic
      }
    }
  }
`;
