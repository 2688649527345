import React from "react";
import { Helmet } from "react-helmet";
import { Chapter } from "../models/Chapter";
import { VerseModel } from "../models/VerseModel";

interface MetaTagsProps {
  chapter: Chapter;
  verseNumber: number;
  isRootURL: boolean;
}

function CleanVerse(verse: string) {
  return verse
    .replace('"', "")
    .replace("“", "")
    .replace("”", "")
    .replace("—", "-")
    .replace("’", "'"); // special character replacement - because they didn't display well in google search
}

function MetaTags(props: MetaTagsProps) {
  const chapter = props.chapter;
  const verseNumber = props.verseNumber;

  const baseUrl = "https://v.corequran.com";
  const iconUrl = "https://v.corequran.com/images/quran-arabic-english-icon.png";
  const infographicUrl = "https://v.corequran.com/images/quran-arabic-english-infographic-lady.jpg";

  let title;
  let description;
  let url;

  if (props.isRootURL) {
    title = `Quran Verse by Verse in Arabic and English`;
    description = `The Holy Quran Verse by Verse. Each Verse on a Page. Clear Arabic and Easy to Understand English.`;
    url = `${baseUrl}`;
  } else {
    title = `Quran ${chapter.chapterNumber}:${verseNumber} Surah ${chapter.nameTrans} Ayah ${verseNumber} in Arabic and English`;
    let verse: VerseModel = chapter.verses.filter(verse => verse.verseNumber === verseNumber)[0];
    description = `${verse.verseStringB} | ${verse.verseString}`;
    description = CleanVerse(description);
    description = CleanVerse(description);
    description = CleanVerse(description);
    description = CleanVerse(description);
    url = `${baseUrl}/${chapter.chapterNumber}#${verseNumber}`;
  }

  return (
    <Helmet
      // structured data
      script={[
        {
          type: "application/ld+json",
          innerHTML: `{
            "@context": "https://schema.org",
            "@type": "Website",
            "url": "${url}",
            "name": "CoreQuran",
            "description": "${description}",      
            "author": "Talal Itani",
            "logo": "${iconUrl}",
            "image": "${iconUrl}"
          }`
        }
      ]}
    >
      <html lang="en" />

      {/* meta tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="author" content="Talal Itani" />
      <meta name="robots" content="index, follow" />

      <meta property="og:url" content={url} />
      <meta property="og:type" content="book" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={infographicUrl} />
      <meta property="og:image:secure_url" content={infographicUrl} />
      <meta property="og:site_name" content="Quran Verse by Verse Arabic English" />
      <meta property="fb:admins" content="683971979" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@ClearQuran" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:creator" content="@ClearQuran" />
      <meta name="twitter:image" content={infographicUrl} />
    </Helmet>
  );
}

export default MetaTags;
