import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { topAppBarColor } from "../constants/BaseConstants";

const useStyles = makeStyles(() => ({
  content: {
    margin: "auto"
  },
  title: {
    letterSpacing: "0.1rem",
    fontSize: "2.5rem",
    color: "#444",
    fontWeight: "bold"
  },
  numbers: {
    fontSize: "2.5rem",
    color: "#555"
  },
  appBar: {
    backgroundColor: topAppBarColor
  },
  iconButtonLeft: {
    marginLeft: "5px"
  }
}));

interface TopBarProps {
  title: string;
  verseNumber: number;
  chapterNumber: number;
  onTitleClicked: (event: React.MouseEvent<HTMLElement>) => void;
  onNumbersClicked: (event: React.MouseEvent<HTMLElement>) => void;
}

function TopBar(props: TopBarProps) {
  const classes = useStyles(props);

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <h1 className={classes.content}>
            <ButtonBase onClick={props.onTitleClicked} title={props.title} aria-label={props.title}>
              <Typography className={classes.title}>{props.title}</Typography>
            </ButtonBase>
            <ButtonBase onClick={props.onNumbersClicked}>
              <Typography className={classes.numbers} noWrap title="Read in Surah" aria-label="Read in Surah">
                &nbsp;{props.chapterNumber}:{props.verseNumber}
              </Typography>
            </ButtonBase>
          </h1>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default TopBar;
