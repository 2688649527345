import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { VerseModel } from "../models/VerseModel";
import { Chapter } from "../models/Chapter";

const useStyles = makeStyles({
  dialog: {
    maxWidth: "625px",
    margin: "auto"
  },
  verseNumber: {
    minWidth: "40px",
    margin: "00px 15px 15px 00px",
    padding: "0px"
  }
});

interface VersesDialogProps {
  isOpen: boolean;
  chapter: Chapter;
  verseNumberClicked: Function;
  handleCloseDialog: (event: React.MouseEvent<HTMLElement>) => void;
}

export default function VersesDialog(props: VersesDialogProps) {
  const classes = useStyles(props);

  return (
    <Dialog className={classes.dialog} open={props.isOpen} onClose={props.handleCloseDialog} aria-labelledby="max-width-dialog-title">
      <DialogTitle id="max-width-dialog-title">Ayah &bull; Verse</DialogTitle>
      <DialogContent>
        {props.chapter.verses.map((item: VerseModel) => (
          <Button
            size="large"
            key={"verse" + item.verseNumber}
            className={classes.verseNumber}
            onClick={() => props.verseNumberClicked(item.verseNumber)}
          >
            {item.verseNumber}
          </Button>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCloseDialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
