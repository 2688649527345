import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ChapterInfo } from "../models/ChapterInfo";

const useStyles = makeStyles({
  chapterNumber: {
    width: "35px",
    fontSize: "16px",
    textAlign: "right"
  },
  chapterNameArabic: {
    fontSize: "20px",
    width: "65px",
    textAlign: "right",
    fontFamily: "Uthmani"
  },
  chapterNameTrans: {
    fontSize: "16px",
    width: "115px"
  },
  horizontalSpacer: {
    width: "30px"
  }
});

interface VersesMenuProps {
  anchor: HTMLElement;
  chaptersInfo: ChapterInfo[];
  chapterClicked: Function;
  handleMenuClose: Function;
  currentChapterNumber: number;
}

export default function VersesMenu(props: VersesMenuProps) {
  const classes = useStyles(props);

  return (
    <Menu anchorEl={props.anchor} open={Boolean(props.anchor)} onClose={() => props.handleMenuClose(null)}>
      {props.chaptersInfo.map((item: ChapterInfo, index: number) => (
        <MenuItem
          dense={true}
          key={"chapter-menu" + item.nameEnglish}
          selected={props.currentChapterNumber === index + 1}
          onClick={() => props.chapterClicked(index)}
        >
          <span className={classes.chapterNumber}>{index + 1} </span>
          <span className={classes.horizontalSpacer}></span>
          <span className={classes.chapterNameArabic}>{item.nameArabic} </span>
          <span className={classes.horizontalSpacer}></span>
          <span className={classes.chapterNameTrans}>{item.nameTrans} </span>
        </MenuItem>
      ))}
    </Menu>
  );
}
