import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import NavigateNext from "@material-ui/icons/NavigateNext";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import { bottomAppBarColor } from "../constants/BaseConstants";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  appBar: {
    top: "auto",
    bottom: 0,
    backgroundColor: bottomAppBarColor,
    borderTop: "1px solid #d0d0d0"
  },
  toolBar: {
    margin: "auto"
  },
  iconButton: {
    color: "#333333"
  },
  centerButton: {
    margin: "0px 00px 0px 00px",
    ["@media (min-width: 400px)"]: {
      margin: "0px 20px 0px 20px"
    }
  }
});

interface BottomBarProps {
  readInVerseClicked: (event: React.MouseEvent<HTMLElement>) => void;
  chapterSelectClicked: (event: React.MouseEvent<HTMLElement>) => void;
  verseSelectClicked: (event: React.MouseEvent<HTMLElement>) => void;
  nextVerseClicked: (event: React.MouseEvent<HTMLElement>) => void;
  prevVerseClicked: (event: React.MouseEvent<HTMLElement>) => void;
}

export default function BottomBar(props: BottomBarProps) {
  const classes = useStyles(props);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <span>
          <IconButton className={classes.iconButton} onClick={props.chapterSelectClicked} title="Select Surah" aria-label="Select Surah">
            <MenuIcon />
          </IconButton>

          <IconButton className={classes.iconButton} onClick={props.verseSelectClicked} title="Select Ayah" aria-label="Select Ayah">
            <MoreVert />
          </IconButton>
        </span>

        <Button
          variant="text"
          className={classes.centerButton}
          onClick={props.readInVerseClicked}
          title="Go to Surah"
          aria-label="Go to Surah"
        >
          Read in Surah
        </Button>

        <span>
          <IconButton className={classes.iconButton} onClick={props.prevVerseClicked} title="Previous Ayah" aria-label="Previous Ayah">
            <NavigateBefore />
          </IconButton>

          <IconButton className={classes.iconButton} onClick={props.nextVerseClicked} title="Next Ayah" aria-label="Next Ayah">
            <NavigateNext />
          </IconButton>
        </span>
      </Toolbar>
    </AppBar>
  );
}
